<template>
    <div>
        <h1 class="text-center text-primary">Carga de productos con archivo</h1>
        <div class="mt-4">
            <span>
                En esta sección puedes cargar, actualizar productos y
                referencias al sistema. Adicionalmente podrás cargar el
                inventario de un producto en el comercio seleccionado en la
                parte superior. A continuación te presentamos dos procesos que
                te permitirán subir los productos al sistema con un archivo de
                forma fácil y rápida.
            </span>
        </div>

        <div class="container mt-5">
            <div class="row mt-4">
                <div class="col-md-6">
                    <router-link
                        to="/app/products/massive/load"
                        class="text-decoration-none"
                    >
                        <div
                            class="button-card text-base rounded border-primary border px-3 py-3"
                            style="cursor: pointer"
                        >
                            <div class="">
                                <h5 class="text-primary">
                                    Carga masiva de productos 🛍
                                </h5>
                                <span>
                                    Permite cargar productos y referencias al
                                    sistema con las siguientes consideraciones:
                                </span>
                                <br />
                                <ul class="list-group ml-4">
                                    <li class="">
                                        Utilizando el SKU del producto como
                                        clave, identifica si un producto ya esta
                                        en el sistema.
                                    </li>
                                    <li class="">
                                        Utilizando el código de barras de la
                                        referencia como clave, identifica si la
                                        referencia ya esta en el sistema.
                                    </li>
                                    <li class="">
                                        Si los productos no están en el sistema,
                                        permite crear el producto y asociarle
                                        una referencia.
                                    </li>

                                    <li class="">
                                        Utilizando el SKU del producto como
                                        clave, agrupa las referencias y crea un
                                        producto con múltiples referencias.
                                    </li>
                                    <li class="">
                                        En caso de que el producto este
                                        registrado en el sistema permite su
                                        actualización.
                                    </li>
                                    <li class="">
                                        En caso de que el producto y la
                                        referencia esten registradas en el
                                        sistema permite sus actualizaciones.
                                    </li>
                                    <li class="">
                                        En caso de que el producto este creado
                                        en el sistema y la referencia no,
                                        actualiza el producto y crea la
                                        referencia.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-6">
                    <router-link
                        to="/app/products/massive/load-with-inventories"
                        class="text-decoration-none"
                    >
                        <div
                            class="button-card text-base rounded border-primary border px-3 py-3"
                            style="cursor: pointer"
                        >
                            <div class="">
                                <h5 class="text-primary">
                                    Carga masiva de productos con inventario 🧮
                                </h5>
                                <span>
                                    Permite cargar productos, referencias e
                                    inventario al sistema, es ideal para la
                                    primera carga de productos. Ten en cuenta
                                    las siguientes consideraciones:
                                </span>
                                <br />
                                <ul class="list-group ml-4">
                                    <li class="">
                                        Tiene las mismas caráteristicas y
                                        restricciones de la carga masiva de
                                        productos. Adicionalmente permite crear
                                        el inventario en el comercio.
                                    </li>
                                    <li class="">
                                        Si los productos no están en el sistema,
                                        permite crear el producto, asociarle una
                                        referencia y registar el inventario en
                                        el comercio selecionado.
                                    </li>
                                    <li class="">
                                        Si un producto esta creado pero la
                                        referencia no crea la referencia y
                                        registar el inventario en el comercio
                                        selecionado.
                                    </li>
                                    <li class="">
                                        Si el producto esta creado, la
                                        referencia estan creados y hay registro
                                        en el inventario, solo actualizará el
                                        producto y la referencia tal cual como
                                        funciona en la carga masiva de
                                        productos. Para actualizar el inventario
                                        lo recomendado es usar el proceso de
                                        carga masiva de inventario.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProductsLoad"
};
</script>
